import * as React from 'react'
import {  Link } from 'gatsby'
import Layout from '../../components/layout'
import '../../styles/global.css' 
import {Helmet} from "react-helmet";



export default function ChristmasShop() {
  return (
    <Layout pageTitle="Christmas Shop">

      <Helmet>
        <meta charset="utf-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <title>Christmas Shopping - USI Projects</title>
        <meta name="description" content="For Christmas 2019, USI converted an empty retail unit on the high street of Royal Avenue in Belfast."/>
        <meta property="og:title" content="Urban Scale Interventions" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://urbanscaleinterventions.com/projects" />
        <meta property="og:image" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      </Helmet>
        <section class="text-gray-700 body-font heading-container-christmas">
        <div class="relative mx-auto h-full px-5 md:px-16 py-24 items-center">
          <section class="self-end">
              <div class="absolute bottom-10">
                <h3 class="text-5xl lg:text-8xl font-heading leading-tight text-white pt-16">Christmas Shop</h3>
                <p class="text-white text-xl md:text-2xl lg:mt-0"><span class="font-bold">Client: </span>
                  Belfast City Council</p>
              </div>
          </section>
        </div>
      </section>





      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 pt-12 lg:pt-24 md:flex-row flex-col items-center">
          <section>
            <div class="flex flex-wrap ">
              <div class="md:w-3/4 py-4 md:px-32 mb-4 md:mb-0">
                <h3 data-scroll data-scroll-speed="1" class="text-6xl font-heading text-purple-800">Overview</h3>
                <p data-scroll data-scroll-speed="1" data-scroll-class="scroll-class" data-scroll-repeat="true"
                  class="my-4 text-black leading-relaxed text-xl md:text-2xl lg:text-3xl  ">For
                  Christmas 2019, USI converted an empty retail unit on the high street of Royal Avenue in Belfast. In
                  collaboration with Belfast City Council, the aim of the hub was to involve residents and visitors in
                  conversations around future uses of the high street through creative&nbsp;engagement.<br/><br/>

                  The hub was fitted out in 'Soda and Champ' festive decoration - the Christmas theme for the year and
                  coincided with city-wide festive animation. At first glance, the hub was a hive of creative festive
                  activities for families and adults; from live music, a playful ballpit, Santa and Christmas elves and
                  a series of creative decoration making&nbsp;workshops. </p>

              </div>
              <div class="md:w-1/4 py-4 md:px-8 mb-4 md:mb-0 md:mt-28">
                <p class="text-2xl font-bold uppercase ">Fields of
                  Practice</p>
                <p 
                  class="my-4 text-gray-500 leading-relaxed   mt-0">Community<br/>
                  Regeneration<br/>Active Citizenship<br/>Policy & Strategy</p>
                <p class="text-2xl font-bold uppercase   mt-8">Services</p>
                <p class="my-4 text-gray-500 leading-relaxed   mt-0">Citizen
                  Engagement<br/>
                  Concept Development<br/>Policy Recommendations<br/>Thought Leadership</p>
              </div>
            </div>
          </section>
        </div>
      </section>



      <img class="w-full mt-32" src="https://ik.imagekit.io/usi/project-pages/17_christmas_hub_16_1_eMDVCkjwe.jpg"
        alt=""/>


        
      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 py-16 md:flex-row flex-col items-center">
          <section>
            <div class="flex flex-wrap ">
              <div class="w-full md:px-40 xl:w-2/4 py-4 xl:px-32 mb-4 md:mb-0">

                <img src="https://ik.imagekit.io/usi/project-pages/17_christmas_hub_5_1_1DPbu9-Hc4v9W.png"/>



              </div>
              <div data-scroll data-scroll-speed="1" class="w-full xl:w-2/4 py-4 sm:px-8 md:px-24 mb-4 md:mb-0">
                <div class="md:pt-9 p-6 md:pl-0 md:pr-0 md:pb-0 md:mb-16">
                  <h2 data-scroll data-scroll-speed="1"
                    class="text-5xl leading-tight font-semibold font-heading text-purple-800">But under the surface, we
                    wanted to engage people in a serious conversation</h2>
                  <ul data-scroll data-scroll-speed="1" class="mt-5 ml-8">
                    <li 
                      class="text-xl md:text-2xl ">&nbsp;What are the current perceptions of
                      Royal&nbsp;Avenue?</li>
                    <li 
                      class="text-xl md:text-2xl  mt-4">&nbsp;What are the challenges, particularly
                      for&nbsp;families?
                    </li>
                    <li
                      class="text-xl md:text-2xl  mt-4">&nbsp;What are the challenges out of trading
                      hours the evening and&nbsp;weekends?</li>

                    <li 
                      class="text-xl md:text-2xl  mt-4">&nbsp;How might we develop a strategy for
                      future highstreets in&nbsp;Belfast?</li>
                  </ul>

                  
                </div>
                <img class="mt-12" src="https://ik.imagekit.io/usi/project-pages/17_christmas_hub_1_1__AF5oqPFKLio.png" alt=""/>
              </div>
            </div>
          </section>
        </div>
      </section>




      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 py-16 md:flex-row flex-col items-center">
          <section>
            <div class="flex flex-wrap ">
              <div data-scroll data-scroll-speed="3" data-scroll-class="scroll-class" data-scroll-repeat="true"
                class="w-full md:w-9/12 md:m-auto xl:w-1/2 py-4 sm:px-8 md:px-24 mb-4 md:mb-0 ">
                <img src="https://ik.imagekit.io/usi/project-pages/17_christmas_hub_17_1_SYGN5N5a6X.png"/>

              </div>
              <div class="w-full md:px-40 xl:w-1/2 py-4 xl:px-32 mb-4 md:mb-0">
                <img src="https://ik.imagekit.io/usi/project-pages/17_christmas_hub_31_1_-kaFOxbgsAbMf.png"/>

                <p data-scroll data-scroll-speed="1" data-scroll-class="scroll-class" data-scroll-repeat="true"
                  class="text-xl md:text-2xl  md:pt-16 p-6 md:pl-0 md:pr-0 md:pb-0 md:mb-16 ">
                  Over 5000 people came into the hub throughout December, to have fun in the run up to Christmas and
                  voice their opinion on the future of the high street. This led to a series of reccomendations
                  including extended trading hours, more city-centre cultural programming and animation, more green
                  spaces in the city, and the permanent use of a highsteet hub for city consultation.
                </p>
              </div>

            </div>
          </section>
        </div>
      </section>

      <div class="bg-gray-200 py-16">
        <div class="text-center">
          <h3
            class="mt-2 text-3xl leading-8 font-extrabold tracking-tight sm:text-5xl sm:leading-10 font-heading text-purple-800">
            Explore more work
          </h3>
          <p class="mt-4 max-w-2xl text-xl md:text-2xl  leading-7 text-black mx-12 md:mx-auto pt-6">
            Want to see more? Take a look at the way we’re responding to changes in the way we live, work and
            play.
          </p>
        </div>

        <div class="max-w-screen grid grid-cols-1 md:grid-cols-2 text-center">
          <div class="">
            <Link to="../../projects" class="carrow cta font-heading flipped text-gray-500 text-sm">All Projects
              <span className='hidden sm:block'></span>
            </Link>
          </div>
          <div class="">
            <Link to="../banana-block" class="carrow cta font-heading text-gray-600">Next Project
              <span className='hidden sm:block'></span>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}
